import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './shared/error.interceptor';
import { AuthInterceptor } from './shared/auth-interceptor';
import { BehaviorService } from './shared/behavior.service';
import { RouterModule } from '@angular/router';
import { FavoriteService } from 'src/app/request/favourite/favorite.service';
import { RequestServiceService } from './request/services/request-service.service';
import { AuthService } from './auth/auth.service';
import { DashboardService } from './dashboard/dashboard.service';
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { CommonService } from './services/common.service';
import { AgmCoreModule } from '@agm/core';
import { ThemeService } from 'ng2-charts'; 
// const URL = "http://35.183.188.110:3003/";
const URL = "http://74.208.206.18:4003";
// const URL = "http://198.251.65.146:4504/";
const config: SocketIoConfig = { url: URL, options: { autoConnect : false } };

// import social buttons module
// import { JwSocialButtonsModule } from 'jw-angular-social-buttons'; 

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule,
    AppRoutingModule,
    SocketIoModule.forRoot({url:"http://74.208.206.18:4003"}),
    ToastrModule.forRoot({ 
      maxOpened:1, 
      preventDuplicates: true,
      timeOut:3000

   }),
    BrowserAnimationsModule,
    HttpClientModule, AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDmvz3A-BAjR77dy4PsaoHJC15mUdffLSA',
      libraries: ['places']
      // apiKey: 'AIzaSyDP8k4JmiTI5JznC1dTbgVfaz512syDQRE', 
      // libraries: ['places']
    })
    
  ],
  providers: [
    BehaviorService,
    FavoriteService,   ThemeService,
    RequestServiceService,
    CommonService,
    AuthService,
    DashboardService,
    BehaviorService,
    {provide:HTTP_INTERCEPTORS, useClass:ErrorInterceptor, multi:true},
    {provide:HTTP_INTERCEPTORS ,useClass:AuthInterceptor, multi:true},
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
