import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

const USER_KEY = 'credentials'
@Injectable()
export class BehaviorService {

    public userData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public tabData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public invoicetabData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public requestTabData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public user: BehaviorSubject<object> = new BehaviorSubject<object>({});
    public requestTab: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public clientModal: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public trimData: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public redirection: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public notification: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public invoiceTab: BehaviorSubject<object> = new BehaviorSubject<object>({});

    
    rootUrl: string = environment.url;

    constructor(private spinner: NgxSpinnerService,) { }


    setUserData(data) {
        this.userData.next(data);
    }

    public getUser(): any {
        const user = localStorage.getItem('credentials');
        if (user) {
            return JSON.parse(user);
        }

        return null;
    }
    setTabinvoice(data) {
        console.log(data,'markinvoice')
        this.invoiceTab.next(data);

    }
    getTabinvoice() {
        console.log('invoiceTab call')
        return this.invoiceTab.asObservable();
    }
    setTabid(data) {
        console.log(data,'ffffffffffffffffffffffffff')
        this.tabData.next(data);

    }
    getTabid() {
        return this.tabData.asObservable();
    }

    setRequestTabid(data) {
        console.log(data,'requestid')
        this.requestTabData.next(data);

    }
    getRequestTabid() {
        return this.requestTabData.asObservable();
    }
    setDirestion(data) {
        this.redirection.next(data)
    }
    getDirestion() {
        return this.redirection.asObservable();
    }
    setNotification(data) {
        console.log(data,'datadata')
        this.notification.next(data)
    }
    getNotification() {
        return this.notification.asObservable();
    }
    public saveUser(user: any): void {
        this.setUserData(user);
        if (user) {
            localStorage.setItem(USER_KEY, JSON.stringify(user));
        } else {
            localStorage.removeItem(USER_KEY)
        }
    }
    setTrim(trimData) {
        this.trimData.next(trimData)
    }
    getTrim() {
        return this.trimData.asObservable();
    }
    getLocalUser(){
        let data:any;
        let user:any = localStorage.getItem(USER_KEY)
        if(user) data = JSON.parse(user)
        return data;
    }


    load(p) {

        if (p) {
            this.spinner.show()
        } else {
            this.spinner.hide()
        }

    }
    closeModal(item) {
        this.clientModal.next(item)
    }
    getModal() {
        return this.clientModal.asObservable();

    }

    getUserData() {
        return this.userData.asObservable();
    }

    setUser(value) {
        let user: object;
        let userObject = { user: value };
        this.user.next(userObject);
        return {};
    }
    UtcToObject(val) {

        if (val && val.day) {
            let dd: any
            if (val != null && val && val.year) {
                dd = val ? new Date(val ? val.year : 0, val ? val.month -1: 0, val ? val.day : 0,) : ''

            }
            return dd
        } else {

            return val
        }

    }
    unsetUser() {
        this.user.next({});
        return {};
    }




}
