import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { Socket } from "ngx-socket-io";
const URL = "http://74.208.206.18:4003";
@Injectable({
  providedIn: "root",
})
@Injectable({
  providedIn: "root",
})
export class CommonService {
  clientAdded = new BehaviorSubject(false);
  addBankModal = new BehaviorSubject({
    modal: null,
    id: ''
  })
  addVehicleModal = new BehaviorSubject({
    modal: null,
    id: ''
  });
  actionType = new BehaviorSubject("active");
  editClientData = new BehaviorSubject('');
  addedById = new BehaviorSubject("");
  addedByRole = new BehaviorSubject("");
  trimEditData = new BehaviorSubject('');
  prospectiveId = new BehaviorSubject("");
  messageCounter = new BehaviorSubject(0);
  trimAdded = new BehaviorSubject(false)
  inspactionLocationToggle = new BehaviorSubject(null)
  adddocumentModal = new BehaviorSubject(false)
  pinCheckData: BehaviorSubject<object> = new BehaviorSubject<object>({
    pinModal: false,
    otpView: true,
    route: '',
    verifyOTP: false
  });
  public cart: BehaviorSubject<object> = new BehaviorSubject<object>({});

  webstore = new BehaviorSubject("productPart");
  private _baseUrl = environment.url;

  constructor(private socket: Socket, private httpClient: HttpClient) {

  }



  setCart(value) {
    //('cart value ',value)
    let cart: object;
    let cartObject = { cart: value };
    this.cart.next(cartObject);
    return {};
  }

  unsetCart() {
    this.cart.next({});
    return {};
  }
  downloadFile(url, context) {
    return this.httpClient.post<Blob>(this._baseUrl + url, context, { observe: 'response', responseType: 'blob' as 'json' })
  }
  add(url, context) {
    return this.httpClient.post(this._baseUrl + url, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  getDetails(params) {
    return this.httpClient.get(this._baseUrl + 'request' + params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  update(url, context) {
    console.log(this._baseUrl + url , context);
    
    return this.httpClient.put(this._baseUrl + url, context).pipe(
      map((response: any) => {
        console.log(this._baseUrl + url , context,'fgjkfgjkdfjkgkjdf');

        return response;
      }),
      catchError(this.handleError)
    );
  }
  updatePut(url, params, context) {
    console.log(url, params, 'zjdfjgdjgsd');

    return this.httpClient.put(this._baseUrl + url + params, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  getWithParms(url, params) {
    return this.httpClient.get(this._baseUrl + url + params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  getByAPIParms(url, param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + url, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getRequest(url, param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + url, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  gets(url, param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + url, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getMakesList() {
    let params = new HttpParams().set('?search', '').set('isDeleted', 'false')
    return this.httpClient.get(this._baseUrl + 'makes' + params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  // get(url) {
  //   return this.httpClient.get(this._baseUrl + url).pipe(
  //     map((response: any) => {
  //       return response;
  //     }),
  //     catchError(this.handleError)
  //   );
  // }
  get(url,param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl +url, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getAll(url: any, param?: any, baseUrl: any = '') {
    let params = new HttpParams();
    let _baseUrl = baseUrl ? baseUrl : this._baseUrl;
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(_baseUrl + url, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  getClientList() {
    return this.httpClient.get(this._baseUrl + 'clients').pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  uploadMultipleDocument(fileToUpload: File) {

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.httpClient.post(this._baseUrl + `multiple/upload/document`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.errorMgmt)
    );
  }
  uploadMultipleDocs(fileToUpload: File,) {

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.httpClient.post(this._baseUrl + `upload/document`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.errorMgmt)
    );
  }
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error.code == 401) {
      return throwError('');
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    }
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;

    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //(errorMessage);
    return throwError(errorMessage);
  }
  status(url, params) {

    return this.httpClient.put(this._baseUrl + url + params, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  statusChangeWithBody(url, params, data) {

    return this.httpClient.put(this._baseUrl + url + params, data, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  statusChangeWithPost(url, params, data) {
    // let url = this._baseUrl + 'changeStatus?id=' + id + '&model=' + model + '&status=' + status;

    return this.httpClient.post(this._baseUrl + url + params, data, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  deleteRecord(url, params) {
    return this.httpClient.delete(this._baseUrl + url + params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  uploadOnlyImage(fileToUpload: File, type) {
    let params = "?modelName=" + type;
    const formData: FormData = new FormData();
    formData.append("data", fileToUpload, fileToUpload.name);
    formData.append("modelName", type);
    return this.httpClient
      .post(this._baseUrl + `uploadImages` + params, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  uploadImage(fileToUpload: File, type) {
    let params = "?modelName=" + type;
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("modelName", type);
    return this.httpClient
      .post(this._baseUrl + `upload/document` + params, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  uploadMultipleImage(params, formData) {

    return this.httpClient.post(this._baseUrl + `multiple/images` + params, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.errorMgmt)
    );
  }

  sendTop() {
    window.scrollTo(500, 0);
  }

  ////////// Socket events///////////////////////////

  public sendMessage(message) {
    this.socket.emit("new-message", message);
  }

  public getMessages = (recipientId) => {
    return new Observable((observer) => {
      this.socket.on(`new-message${recipientId}`, (message) => {
        observer.next(message);
      });
    });

  };

  public sendOne2OneMessage(message) {
    this.socket.emit("one-to-one", message);
  };

  public disconnect = () => {
    return Observable.create((observer) => {
      this.socket.on("disconnect", (message) => {
        observer.next(message);
      });
    });
  };
  public connect = () => {

    //("in connect");
    return new Observable((observer) => {
      this.socket.on("connection", (message) => {
        observer.next(message);
      });
    });
  };

  public addUser(newUser) {
    this.socket.emit("new-user", newUser);
    //('add user')
  }

  public removeUser(newUser) {
    this.socket.emit("user-offline", newUser);
    //('user offline')
  }

  public showOnline(newUser) {
    this.socket.emit("user-online", newUser);
    //('user online')
  }

  public sendMessageEvent(eventName, message) {
    this.socket.emit(eventName, message);
  }

  public fetch_history = (user) => {
    let params = new HttpParams();
    params.set("email", user.email);
    return this.httpClient.get(
      URL + "/admin/fetch_history?email=" + user.email
    );
  };

  public user_history = (to: string, from: string) => {
    return this.httpClient.get(
      URL + "/chat/user_history?from=" + from + "&to=" + to
    );
  };

  put(body, path) {
    let headers = new HttpHeaders();
    return this.httpClient.put(URL + path, body, {
      headers: headers,
    });
  }



  public user_list = (id: string) => {
    let headers = new HttpHeaders();
    return this.httpClient.get("http://198.251.65.146:4705/chatUser?id=" + id, {
      headers: headers,
    });
  };
  findObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }
  deleteImage(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .delete(this._baseUrl + "deleteImage", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteDocument(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    //('params',param)

    return this.httpClient.delete(this._baseUrl + 'delete/document', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }
  handleError(error: HttpErrorResponse) {
    //(error);
    if (error.error.code == 401) {
      return throwError("");
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    }
    else if (error.error.code == 400) {
      return throwError(error.error.message);
    }
    return throwError("Something bad happened; please try again later.");
  }
}
